import React from 'react';
import GoogeAdsCatsTable from '../components/googleAdsSubCats';

const GoogleAdsSubCats = () => {
  return (
    <>
      <GoogeAdsCatsTable />
    </>
  );
};

export default GoogleAdsSubCats;
