import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import Loader from '../../common/UI/Loader';
import { Form, Formik } from 'formik';
import Input from '../../common/FormComponents/Input';
import ImageInput from '../../common/FormComponents/ImageInput';
import TextEditor from '../../common/TextEditor';
import { DIMENSION } from '../../common/constants';
import { Button } from 'react-bootstrap';
import plusBlueIcon from '../../assets/images/plus-blue.svg';
import {
  getFormDataFromObject,
  validateFileExtensions,
} from '../../utils/helpers';
import deleteIcon from '../../assets/images/trash.svg';
import { cloneDeep } from 'lodash';
import { toastError, toastSuccess } from '../../common/UI/Toasts';
import ReactTextareaAutosize from 'react-textarea-autosize';
import editIcon from '../../assets/images/pencil.svg';


const GoogeAdsCatTable = () => {
  const [generalLpData, setGeneralLpData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lpImage, setLpImage] = useState();
  const [currentFAQObj, setCurrentFAQObj] = useState({
    question: '',
    answer: '',
  });
  const [currentTickPoint, setCurrentTickPoint] = useState({
    value: '',
  });

  useEffect(() => {
    setLoading(true)
    fetchGeneralLpData()
  }, []);

  const fetchGeneralLpData = () => {
    api.getGeneralLpData()
    .then((res) => {
      setGeneralLpData(res.data.data[0])
      setLpImage(res.data.data[0]?.general_landingpage_image);
      setLoading(false)
    })
    .catch((err) => {
      console.log(err, 'error');
    })
  }

  const handleSubmit = (values) => {
    let payload = {
      general_landingpage_title: values.general_landingpage_title,
      general_landingpage_description: values.general_landingpage_description,
      general_landingpage_image: lpImage,
    };
    generalLpData?.general_landingpage_faq?.length &&
    generalLpData?.general_landingpage_faq.forEach((el, index) => {
      payload[`general_landingpage_faq[${index}]`] = JSON.stringify(el);
    });

    generalLpData?.general_landingpage_tickpoints?.length &&
    generalLpData?.general_landingpage_tickpoints.forEach((el, index) => {
      payload[`general_landingpage_tickpoints[${index}]`] = JSON.stringify(el);
    });
    const formDataPayload = getFormDataFromObject(payload);
    
    api.updateGeneralLpData(formDataPayload, true).
    then((res) => {
      setGeneralLpData(res.data.data)
      toastSuccess("General landing page data Updated!")
    })
  } 

  const handleUploadLPImage = (imageData) => {
    api
      .uploadOrderImage(getFormDataFromObject({ photos: imageData.answer }))
      .then((res) => {
        setLpImage(res.data.data.photos[0].location);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handleChangeLPFaqTick = (name, value) => {    
    if (name === 'general_landingpage_faq') {
      let faqClone = [...generalLpData.general_landingpage_faq];
      
      if (value?.index || value?.index === 0)
        faqClone[value?.index] = {
          question: value?.question,
          answer: value?.answer,
        };
      else faqClone.push(value);

      setGeneralLpData((prev) => {
        return { ...prev, general_landingpage_faq: faqClone };
      });
      
      return;
    } else if (name === 'general_landingpage_tickpoints') {
      let tickPointsClone = [...generalLpData.general_landingpage_tickpoints];
      if (value?.index || value?.index === 0)
        tickPointsClone[value?.index] = value?.value;
      else tickPointsClone.push(value?.value);
      setGeneralLpData((prev) => {
        return { ...prev, general_landingpage_tickpoints: tickPointsClone };
      });
      return;
    }
  };

  const setFAQEditValue = (index) => {
    setCurrentFAQObj({
      ...generalLpData?.general_landingpage_faq[index],
      index: index,
    });
  };
  const deleteFAQAndTickPoints = (name, index) => {
    if (name === 'general_landingpage_faq') {
      const { general_landingpage_faq } = generalLpData;
      
      let faqClone = [...general_landingpage_faq];
      faqClone.splice(index, 1);
      setGeneralLpData((prev) => {
        return { ...prev, general_landingpage_faq: faqClone };
      });
    } else if (name === 'general_landingpage_tickpoints') {
      const { general_landingpage_tickpoints } = generalLpData;
      let tickPointsClone = [...general_landingpage_tickpoints];
      tickPointsClone.splice(index, 1);
      setGeneralLpData((prev) => {
        return { ...prev, general_landingpage_tickpoints: tickPointsClone };
      });
    }
  };

  const setTickPointsEditValue = (index) => {
    setCurrentTickPoint({
      value: generalLpData?.general_landingpage_tickpoints[index],
      index: index,
    });
  };

  return (
    <>
      <div className="content_wrapper all_products_main product_add_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div className="header_top">
                  <h3>Google Ads: General</h3>
                </div>
                <div className="middle_main">
                  <div className="row">
                    <div className="col-md-12 mb-0 mt-4">
                      {loading ? (
                        <Loader loading={loading} />
                      ) : 
                      (
                        <Formik
                          initialValues={generalLpData}
                          onSubmit={(values) => {
                            handleSubmit(values);
                          }}
                          validateOnChange={false}
                          // validationSchema={validationSchema}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="middle_main_2">
                                  <div className="row">
                                    <div className="col-xl-3 col-md-3 col-lg-3 form-group">
                                      <Input
                                        label="Title"
                                        labelClassname="form-label"
                                        name="general_landingpage_title"
                                        type="text"
                                        className="form-control form-input"
                                        placeholder="Title"
                                        value={formik.values.general_landingpage_title}
                                        isRequired
                                      />
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12 col-xl-8 form-group">
                                        <label className="form-label">
                                          Description
                                          <span className="text-danger">*</span>
                                        </label>
                                        <TextEditor
                                          name="general_landingpage_description"
                                          value={formik.values.general_landingpage_description}
                                          className={'position-relative'}
                                          onEditorChange={(value) => {
                                            formik.setFieldValue(
                                              'general_landingpage_description',
                                              value,
                                              true,
                                            );
                                          }}
                                          editorHeight="30vh"
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12">
                                        <h4 className="middle_title">
                                          Landing Page Image
                                          <span className="text-danger ml-1">*</span>
                                        </h4>
                                        <div className="d-flex">
                                          <div
                                            className="col-2 mb-2"
                                            style={{ height: '10rem', width: '11rem' }}
                                          >
                                            <span className="upload_text">
                                              <label style={{ cursor: 'pointer' }}>
                                                <img
                                                  src={plusBlueIcon}
                                                  width="12"
                                                  height="12"
                                                  className="img-fluid custom_radius me-2"
                                                  alt="plus"
                                                />
                                                <input
                                                  type="file"
                                                  accept="image/png, image/svg, image/jpeg, image/jpg, image/PNG, image/SVG, image/JPEG, image/JPG, image/webp, image/heic"
                                                  style={{
                                                    width: '300px',
                                                    display: 'none',
                                                  }}
                                                  onChange={(e) => {
                                                    if (
                                                      validateFileExtensions(
                                                        e.target.files[0],
                                                      )
                                                    ) {
                                                      handleUploadLPImage({
                                                        answer: e.target.files[0],
                                                        prevUrl: URL.createObjectURL(
                                                          e.target.files[0],
                                                        ),
                                                      });
                                                    }
                                                  }}
                                                />
                                              </label>
                                            </span>
                                          </div>
                                          {lpImage && (
                                            <div className="d-flex">
                                              <div className="position-relative hover_effect_main">
                                                <img
                                                  src={lpImage}
                                                  className="img-fluid custom_radius"
                                                  style={{
                                                    height: '100%',
                                                    width: '12rem',
                                                  }}
                                                />
                                                <div className="hover_effect_child">
                                                  <button
                                                    type="button"
                                                    onClick={() => {
                                                      setLpImage(null);
                                                    }}
                                                  >
                                                    <img
                                                      src={deleteIcon}
                                                      width="24"
                                                      height="18"
                                                      alt="trash"
                                                      className="mx-2"
                                                    />
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-12 mt-2">
                                          <div className="d-flex align-items-center">
                                            <label className="form-label" htmlFor={'general_landingpage_faq'}>
                                              Add Landing Page FAQs:
                                            </label>
                                            <Button
                                              className="ms-4"
                                              onClick={() => {
                                                if (
                                                  currentFAQObj?.question !== '' &&
                                                  currentFAQObj?.answer !== ''
                                                ) {
                                                  handleChangeLPFaqTick('general_landingpage_faq', currentFAQObj);
                                                  setCurrentFAQObj({
                                                    question: '',
                                                    answer: '',
                                                  });
                                                } else {
                                                  toastError('Question and Answer both are required.');
                                                }
                                              }}
                                            >
                                              Save Changes
                                            </Button>
                                          </div>
                                          <div className="ms-4">
                                            <div className="d-flex mt-3">
                                              <label
                                                className="form-label mb-0"
                                                style={{
                                                  fontSize: '0.8rem',
                                                }}
                                              >
                                                Question
                                              </label>
                                              <ReactTextareaAutosize
                                                type="text"
                                                className="form-control ms-3 w-50"
                                                placeholder=""
                                                onChange={(e) =>
                                                  setCurrentFAQObj((prev) => {
                                                    return {
                                                      ...prev,
                                                      question: e.target.value,
                                                    };
                                                  })
                                                }
                                                value={currentFAQObj?.question}
                                                minRows={1}
                                              />
                                            </div>
                                            <div className="d-flex mt-3">
                                              <label
                                                className="form-label mb-0"
                                                style={{
                                                  fontSize: '0.8rem',
                                                }}
                                              >
                                                Answer
                                              </label>
                                              <TextEditor
                                                className={'w-50 ms-4'}
                                                onEditorChange={(value) => {
                                                  setCurrentFAQObj((prev) => {
                                                    return {
                                                      ...prev,
                                                      answer: value,
                                                    };
                                                  });
                                                }}
                                                value={currentFAQObj?.answer}
                                                editorHeight="30vh"
                                              />
                                            </div>
                                          </div>
                                          {generalLpData?.general_landingpage_faq?.length
                                            ? generalLpData?.general_landingpage_faq?.map((el, index) => {
                                                return (
                                                  <>
                                                    {el?.question != '' ? (
                                                      <React.Fragment key={index}>
                                                        <div className="mb-3 w-100">
                                                          <div className="d-flex justify-content-between">
                                                            <label
                                                              className="form-label mb-0 fs-18 w-100"
                                                              style={{
                                                                wordWrap: 'break-word',
                                                              }}
                                                            >
                                                              Q) {el?.question}
                                                            </label>
                                                            <div className="d-flex">
                                                              <span onClick={() => setFAQEditValue(index)}>
                                                                <img
                                                                  src={editIcon}
                                                                  alt="pencil"
                                                                  width="20"
                                                                  height="20"
                                                                />
                                                              </span>
                                                              <span
                                                                onClick={() => {
                                                                  if (
                                                                    (currentFAQObj?.index ||
                                                                      currentFAQObj?.index === 0) &&
                                                                    currentFAQObj?.index === index
                                                                  )
                                                                    setCurrentFAQObj({
                                                                      question: '',
                                                                      answer: '',
                                                                    });
                                                                  deleteFAQAndTickPoints('general_landingpage_faq', index);
                                                                }}
                                                              >
                                                                <img
                                                                  src={deleteIcon}
                                                                  alt="trash"
                                                                  width="20"
                                                                  height="20"
                                                                />
                                                              </span>
                                                            </div>
                                                          </div>
                                                          <div
                                                            style={{
                                                              backgroundColor: '#d3d3d359',
                                                              borderRadius: '5px',
                                                              padding: '10px',
                                                              wordWrap: 'break-word',
                                                            }}
                                                            dangerouslySetInnerHTML={{
                                                              __html: el?.answer,
                                                            }}
                                                            className="w-100"
                                                          ></div>
                                                        </div>
                                                      </React.Fragment>
                                                    ) : null}
                                                  </>
                                                );
                                              })
                                            : null}
                                        </div>
                                        <div className="col-12 mt-2">
                                          <label
                                            className="form-label mb-0"
                                            style={{
                                              fontSize: '1rem',
                                            }}
                                          >
                                            Insert Landing Page Tick Points:
                                          </label>
                                          <input
                                            type={'text'}
                                            className="form-input ms-2"
                                            value={currentTickPoint?.value}
                                            onChange={(e) =>
                                              setCurrentTickPoint((prev) => {
                                                return { ...prev, value: e.target.value };
                                              })
                                            }
                                          />
                                          <Button
                                            className="order_btns ms-2"
                                            onClick={() => {
                                              if (currentTickPoint?.value !== '') {
                                                handleChangeLPFaqTick('general_landingpage_tickpoints', currentTickPoint);
                                                setCurrentTickPoint({ value: '' });
                                              } else {
                                                toastError('Please add point');
                                              }
                                            }}
                                          >
                                            Add
                                          </Button>
                                          <div className="row mt-3 d-flex">
                                            {generalLpData?.general_landingpage_tickpoints?.length
                                              ? generalLpData?.general_landingpage_tickpoints?.map((el, index) => {
                                                  return (
                                                    <>
                                                      {el?.question != '' ? (
                                                        <React.Fragment key={index}>
                                                          <div
                                                            className="d-flex justify-content-between mb-3"
                                                            style={{
                                                              width: 'fit-content',
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                backgroundColor: '#d3d3d359',
                                                                borderRadius: '5px',
                                                                padding: '10px',
                                                                wordWrap: 'break-word',
                                                              }}
                                                              onClick={() => setTickPointsEditValue(index)}
                                                              // className="w-100"
                                                            >
                                                              {el}
                                                            </div>
                                                            <div
                                                              className="d-flex align-items-center justify-content-center ms-1"
                                                              style={{
                                                                width: '42px',
                                                                backgroundColor: '#d78c8c59',
                                                                borderRadius: '30px',
                                                              }}
                                                              onClick={() => {
                                                                if (
                                                                  (currentTickPoint?.index ||
                                                                    currentTickPoint?.index === 0) &&
                                                                  currentTickPoint?.index === index
                                                                )
                                                                  setCurrentTickPoint({
                                                                    value: '',
                                                                  });
                                                                deleteFAQAndTickPoints('general_landingpage_tickpoints', index);
                                                              }}
                                                            >
                                                              {/* <span
                                                                  onClick={() => setTickPointsEditValue(index)}
                                                                >
                                                                  <img
                                                                    src={editIcon}
                                                                    alt="pencil"
                                                                    width="20"
                                                                    height="20"
                                                                  />
                                                                </span> */}
                                                              <span>
                                                                <img
                                                                  src={deleteIcon}
                                                                  alt="trash"
                                                                  width="20"
                                                                  height="20"
                                                                />
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </React.Fragment>
                                                      ) : null}
                                                    </>
                                                  );
                                                })
                                              : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <Button className="me-2" type="submit">
                                  Save
                                </Button>
                              </Form>
                            );
                          }}
                        </Formik>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogeAdsCatTable;
