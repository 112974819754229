import React from 'react';
import GoogeAdsCatsTable from '../components/googleAdsCat';

const GoogleAdsCat = () => {
  return (
    <>
      <GoogeAdsCatsTable />
    </>
  );
};

export default GoogleAdsCat;
